import { useState } from 'react';

export type T_UseToggle = [boolean, () => void, () => void];

const useToggle = (): T_UseToggle => {
	const [open, setOpen] = useState<boolean>(false);

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	return [open, handleOpen, handleClose];
};

export default useToggle;
