import { cartStorageName, userStorageName } from '@/services/apis/config';
import { postLogout } from '@labServices/apis/arogga-api';
import { isServer } from './cmnHelper';
import { ereaseToken, setLoggingOut } from './inMemoryJWTManager';

export function getCookie(cname: string) {
	const name = cname + '=';
	const decodedCookie = typeof window !== 'undefined' && document && decodeURIComponent(document.cookie);
	const ca = decodedCookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
}

export function getItemFromLocalStorage(name: string) {
	const item = isServer() ? null : JSON.parse(localStorage.getItem(name)!);
	return item;
}

export function setItemInLocalStorage(name: string, value: any) {
	const item = !isServer() && JSON.stringify(localStorage.setItem(name, JSON.stringify(value)));
	return item;
}

export function removeItemFromLocalStorage(name: string) {
	!isServer() && localStorage.removeItem(name);
}

export const handleLogout = () => {
	setLoggingOut(true);
	postLogout().then(() => {
		setLoggingOut(false);
	});
	removeItemFromLocalStorage(userStorageName);
	removeItemFromLocalStorage(cartStorageName);
	ereaseToken();
};

export const setInitialCartDataToLS = (data: any[]) => {
	const cartObj: Record<string, any> = {};
	for (let i = 0; i < data?.length; i++) {
		const currElem = data[i];
		const cartItem = currElem?.item;
		cartObj[cartItem.id] = { patientCount: currElem.patientCount };
	}
	setItemInLocalStorage(cartStorageName, cartObj);
};

export const setInitialCartDataForLTList = (data: any[]) => {
	const cartObj: Record<string, any> = {};
	for (let i = 0; i < data.length; i++) {
		const currElem = data[i];
		cartObj[currElem.id] = { patientCount: currElem.patientCount };
	}
	setItemInLocalStorage(cartStorageName, cartObj);
};
