import { API_BASE_URL, userStorageName } from '@/services/apis/config';
import { getItemFromLocalStorage, removeItemFromLocalStorage, setItemInLocalStorage } from './session';

let inMemoryJWT: null | string = null;
let isRefreshing: Promise<any> | null = null;
let logoutEventName: string = 'ra-logout';
let isLoggingOut = false;
let refreshEndpoint: string = '/refresh-token';
let refreshTimeOutId: number;

const setLogoutEventName = (name: string): string => (logoutEventName = name);

const setRefreshTokenEndpoint = (endpoint: string): string => (refreshEndpoint = endpoint);

// This countdown feature is used to renew the JWT before it's no longer valid
// in a way that is transparent to the user.
const refreshToken = (delay: number): void => {
	abordRefreshToken();
	refreshTimeOutId = window.setTimeout(getRefreshedToken, delay * 1000 - 5000); // Validity period of the token in seconds, minus 5 seconds
};

const abordRefreshToken = (): void => {
	if (refreshTimeOutId) {
		window.clearTimeout(refreshTimeOutId);
	}
};

const waitForTokenRefresh = (): Promise<boolean | void> => {
	if (!isRefreshing) {
		return Promise.resolve();
	}
	return isRefreshing
		.then(() => {
			isRefreshing = null;
			return true;
		})
		.catch((err) => console.log(err));
};

// The method make a call to the refresh-token endpoint
// If there is a valid cookie, the endpoint will set a fresh jwt in memory.
const getRefreshedToken = (): Promise<boolean | void> => {
	if (isLoggingOut === true) {
		return Promise.resolve();
	}

	const request = new Request(`${API_BASE_URL}/auth/v1/token/refresh/`, {
		method: 'POST',
		headers: new Headers({ 'Content-Type': 'application/json' }),
		credentials: 'include'
	});

	isRefreshing = fetch(request)
		.then((response) => response.json())
		.then((response) => {
			console.log(response);
			if (response?.data?.authToken) {
				setToken(response.data.authToken, response.data.tokenExpiry);
				return true;
			}
			/* if (response?.status === "fail") {
				ereaseToken();
				handleLogout();
			} */
			return false;
		})
		.catch(() => {
			ereaseToken();
			removeItemFromLocalStorage(userStorageName);
			removeItemFromLocalStorage('cartData');
		});

	return isRefreshing;
};

const getToken = (): null | string => {
	if (!inMemoryJWT) {
		const user = getItemFromLocalStorage(userStorageName);
		inMemoryJWT = user?.authToken;
	}
	return inMemoryJWT;
};

const setToken = (token: string, delay: number): boolean => {
	inMemoryJWT = token;
	refreshToken(delay);
	return true;
};

const setLoggingOut = (LoggingOut: any) => (isLoggingOut = LoggingOut);

const ereaseToken = (): boolean => {
	inMemoryJWT = null;
	abordRefreshToken();
	setItemInLocalStorage(logoutEventName, Date.now().toString());
	return true;
};

// This listener will allow to disconnect a session of ra started in another tab
// window.addEventListener('storage', (e: StorageEvent) => {
//     if (e.key === logoutEventName) {
//         inMemoryJWT = null;
//     }
// });

export {
	ereaseToken,
	getRefreshedToken,
	getToken,
	isRefreshing,
	setLoggingOut,
	setLogoutEventName,
	setRefreshTokenEndpoint,
	setToken,
	waitForTokenRefresh
};
