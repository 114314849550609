'use client';

import { updateOnlineVisibility } from '../apis/doc-consultation-apis/doctor';

let intervalId: NodeJS.Timeout | null = null;

export const onlineVisibilityActive = () => {
	const handleCallApi = async () => {
		await updateOnlineVisibility({ isOnline: true });
	};

	handleCallApi();

	intervalId = setInterval(() => handleCallApi(), 2 * 60 * 1000);
};

export const offLineVisibilityActive = () => {
	if (intervalId) {
		clearInterval(intervalId);
		intervalId = null;
	}
	updateOnlineVisibility({ isOnline: false });
};
